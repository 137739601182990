import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "content-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavRail = _resolveComponent("NavRail")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Toast = _resolveComponent("Toast")!
  const _component_NavBar = _resolveComponent("NavBar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.$device.isDesktop)
        ? (_openBlock(), _createBlock(_component_NavRail, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component, route }) => [
          _createVNode(_Transition, {
            mode: "out-in",
            name: "show-route"
          }, {
            default: _withCtx(() => [
              (!_ctx.loading)
                ? (_openBlock(), _createElementBlock("div", {
                    class: "content",
                    key: route.name
                  }, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_Toast),
    (_ctx.$device.isMobile)
      ? (_openBlock(), _createBlock(_component_NavBar, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}