import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-73c968bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "banner" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: "material-icons-round",
      innerHTML: _ctx.icon
    }, null, 8, _hoisted_2),
    _createElementVNode("span", {
      class: "body-small",
      textContent: _toDisplayString(_ctx.text)
    }, null, 8, _hoisted_3)
  ]))
}