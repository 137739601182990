
import { defineComponent } from "vue";

export default defineComponent({
  name: "Top App Bar",

  props: {
    title: { type: String, default: "Page Title" },
    scrollEl: { type: String },
    centred: { type: Boolean, default: false },
    medium: { type: Boolean, default: false },
    large: { type: Boolean, default: false },
  },

  data: () => ({
    scrollY: 0,
  }),

  computed: {
    barClasses() {
      return {
        centred: this.centred,
        medium: this.isMedium,
        large: this.isLarge,
        "on-scroll": this.scrollY > 0,
      };
    },
    headlineClass() {
      return this.isLarge
        ? "headline-medium"
        : this.isMedium
        ? "headline-small"
        : "title-large";
    },
    isMedium() {
      return this.medium && !this.isLarge;
    },
    isLarge() {
      return this.large;
    },
  },

  methods: {
    init() {
      (!this.scrollEl
        ? window
        : document.querySelector(this.scrollEl)
      )?.addEventListener("scroll", () => this.getScrollY());
    },
    unInit() {
      (!this.scrollEl
        ? window
        : document.querySelector(this.scrollEl)
      )?.removeEventListener("scroll", () => this.getScrollY());
    },
    getScrollY() {
      if (!this.scrollEl) this.scrollY = window.scrollY;
      else this.scrollY = document.querySelector(this.scrollEl)?.scrollTop ?? 0;
    },
  },

  mounted() {
    this.init();
    this.getScrollY();
  },

  beforeUnmount() {
    this.unInit();
  },
});
