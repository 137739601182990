import { vShow as _vShow, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, {
    mode: "out-in",
    name: "toast-transition"
  }, {
    default: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("div", {
        key: _ctx.text,
        class: _normalizeClass(["toast-container", { 'navigation-hidden': _ctx.navigationIsHidden }]),
        textContent: _toDisplayString(_ctx.text),
        style: _normalizeStyle({ 'z-index': _ctx.zIndex })
      }, null, 14, _hoisted_1)), [
        [_vShow, _ctx.text]
      ])
    ]),
    _: 1
  }))
}