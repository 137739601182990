
import { defineComponent } from "vue";

export default defineComponent({
  name: "Popup",

  props: {
    title: { type: String, default: "" },
    description: { type: String, default: "" },
    modelValue: { type: Boolean, default: false },
  },

  emits: ["update:modelValue"],

  data: () => ({
    diff: 0,
    isTap: false,
    position: {
      start: 0,
      current: 0,
    },
    height: "" as string | number,
    timestamp: 0,
  }),

  computed: {
    show: {
      get() {
        return this.modelValue;
      },
      set(value: boolean) {
        this.height = "";
        this.$emit("update:modelValue", value);
      },
    },

    style() {
      return {
        height: this.num2px(this.height),
      };
    },
  },

  methods: {
    num2px(data: string | number) {
      return data + (typeof data === "number" ? "px" : "");
    },

    hasScrollingElement(e: { path: HTMLElement[] }) {
      const el = this.$refs.content as HTMLElement;
      const { path } = e;

      for (const i of path) {
        if (el == i) return false;
        if (i.scrollTop != 0) return true;
      }
      return false;
    },

    onTap(e: TouchEvent) {
      const el = this.$refs.content as HTMLElement;
      const positionY = e?.touches?.[0]?.pageY;
      if (!el || !positionY) return;

      this.isTap = false;
      this.height = "";
      this.timestamp = Date.now();
      this.position.start = positionY;
    },
    onTapMove(e: TouchEvent) {
      const el = this.$refs.content as HTMLElement;
      const positionY = e?.touches?.[0]?.pageY;
      if (
        positionY - this.position.start < 10 ||
        this.hasScrollingElement(e as any)
      )
        return;
      if (!this.height)
        this.diff = el.getBoundingClientRect().y - positionY + 24;

      this.isTap = true;
      this.height = document.body.clientHeight - positionY - this.diff;
      this.position.current = positionY;
    },
    onTapEnd() {
      const diff = this.position.current - this.position.start > 80;
      const timestamp = Date.now() - this.timestamp < 500;
      if (this.isTap && (diff || timestamp))
        return setTimeout(() => (this.show = false), 0);

      this.isTap = false;

      let height = "";
      const el = this.$refs.content as HTMLElement;
      const oldHeight = getComputedStyle(el).height;

      el.style.height = "auto";
      height = getComputedStyle(el).height;
      el.style.height = oldHeight;

      this.isTap = false;
      getComputedStyle(el).height;
      requestAnimationFrame(() => {
        el.style.height = height;
        setTimeout(() => (this.height = ""), 300);
      });
    },
  },
});
