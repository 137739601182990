import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["textContent"]
const _hoisted_2 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["top-app-bar", _ctx.barClasses])
  }, [
    _renderSlot(_ctx.$slots, "navigation"),
    _createElementVNode("div", {
      class: _normalizeClass(["headline", _ctx.headlineClass]),
      textContent: _toDisplayString(_ctx.title)
    }, null, 10, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "actions")
    ])
  ], 2))
}