import { vShow as _vShow, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c84a4ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["textContent"]
const _hoisted_2 = { class: "body" }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_Transition, { name: "popup-transition" }, {
      default: _withCtx(() => [
        (_ctx.show)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "popup",
              onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.show = false), ["self"]))
            }, [
              _createElementVNode("div", {
                ref: "content",
                style: _normalizeStyle(_ctx.style),
                class: _normalizeClass(["popup-content", { 'no-animation': _ctx.isTap }]),
                onTouchstart: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onTap && _ctx.onTap(...args))),
                onTouchmove: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onTapMove && _ctx.onTapMove(...args))),
                onTouchend: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onTapEnd && _ctx.onTapEnd(...args))),
                onTouchcancel: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onTapEnd && _ctx.onTapEnd(...args)))
              }, [
                _withDirectives(_createElementVNode("span", {
                  class: "title-medium",
                  textContent: _toDisplayString(_ctx.title)
                }, null, 8, _hoisted_1), [
                  [_vShow, _ctx.title]
                ]),
                _createElementVNode("div", _hoisted_2, [
                  _withDirectives(_createElementVNode("span", {
                    textContent: _toDisplayString(_ctx.description),
                    class: "body-medium description"
                  }, null, 8, _hoisted_3), [
                    [_vShow, _ctx.description]
                  ]),
                  _renderSlot(_ctx.$slots, "body", {}, undefined, true)
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _renderSlot(_ctx.$slots, "actions", {}, undefined, true)
                ])
              ], 38)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}