
import { defineComponent } from "vue";

export default defineComponent({
  name: "Dialog",

  props: {
    title: { type: String },
    description: { type: String },
    permanent: { type: Boolean, default: false },
    modelValue: { type: Boolean, default: false },
  },

  emits: ["update:modelValue"],

  data: () => ({
    clicked: false,
  }),

  computed: {
    show: {
      get() {
        return this.modelValue;
      },
      set(v: boolean) {
        this.$emit("update:modelValue", v);
      },
    },
  },

  methods: {
    onMask() {
      if (this.permanent) {
        this.clicked = true;
        setTimeout(() => (this.clicked = false), 300);
      } else this.show = false;
    },

    listener(e: KeyboardEvent) {
      if (!this.show || e.code !== "Escape") return;
      e.preventDefault();
      this.show = false;
    },
  },

  beforeMount() {
    document.addEventListener("keyup", this.listener);
  },
  beforeUnmount() {
    document.removeEventListener("keyup", this.listener);
  },
});
