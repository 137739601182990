import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f33bc91a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["textContent"]
const _hoisted_2 = ["type", "name", "value", "autocomplete"]
const _hoisted_3 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["field", { empty: !_ctx.modelValue, error: _ctx.error, focussed: _ctx.focussed }])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "label",
          textContent: _toDisplayString(_ctx.label)
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      type: _ctx.type,
      name: _ctx.name,
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.inputText && _ctx.inputText(...args))),
      autocomplete: _ctx.autocomplete,
      onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.focussed = true)),
      onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.focussed = false))
    }, null, 40, _hoisted_2),
    _createElementVNode("span", {
      class: "icon material-icons-round",
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update:modelValue', ''))),
      textContent: _toDisplayString(_ctx.error ? '' : '')
    }, null, 8, _hoisted_3)
  ], 2))
}