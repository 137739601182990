
import { defineComponent } from "vue";

export default defineComponent({
  name: "Text Field",

  props: {
    type: { type: String, default: "text" },
    name: { type: String, default: "" },
    label: { type: String, default: "" },
    error: { type: Boolean, default: false },
    modelValue: { type: String, default: "" },
    autocomplete: { type: String, default: "off" },
  },

  emits: ["update:modelValue"],

  data: () => ({
    focussed: false,
  }),

  methods: {
    inputText(e: Event) {
      const el = e.target as HTMLInputElement;
      if (!el) return;
      this.$emit("update:modelValue", el.value);
    },
  },
});
