
import { defineComponent } from "vue";

export default defineComponent({
  name: "Banner",

  props: {
    icon: { type: String, default: "&#xe001;" },
    text: { type: String, required: true },
  },
});
