
import { defineComponent, defineAsyncComponent } from "vue";
import Toast from "@/components/ui/toast.vue";

export default defineComponent({
  name: "App Component",

  components: {
    Toast,
    NavBar: defineAsyncComponent(
      () => import("@/components/ui/navigation-bar")
    ),
    NavRail: defineAsyncComponent(
      () => import("@/components/ui/navigation-rail")
    ),
  },

  computed: {
    loading() {
      return !this.$fb.users.state;
    },
  },

  watch: {
    loading() {
      this.updateState();
    },
  },

  methods: {
    updateState() {
      const el = document.getElementById("loading-page");
      if (el) el.dataset.hide = (!this.loading).toString();
    },
  },

  mounted() {
    this.updateState();
  },
});
