
import { defineComponent } from "vue";

export default defineComponent({
  name: "Top App Bar Item",

  props: {
    to: {},
    replace: { type: Boolean, default: false },

    icon: { type: String, required: true },
    iconRound: { type: Boolean, default: false },
    iconOutlined: { type: Boolean, default: false },
  },

  emits: ["click"],

  computed: {
    iconClass() {
      return this.iconRound
        ? "material-icons-round"
        : this.iconOutlined
        ? "material-icons-outlined"
        : "material-icons";
    },
  },

  methods: {
    onclick(e: Event) {
      if (this.to) {
        if (this.replace) this.$router.replace(this.to as any);
        else this.$router.push(this.to as any);
      } else this.$emit("click", e);
    },
  },
});
