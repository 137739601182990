
import { defineComponent, PropType } from "vue";
import { RouteLocationRaw } from "vue-router";

export default defineComponent({
  name: "Button",

  props: {
    to: Object as PropType<RouteLocationRaw>,
    replace: { type: Boolean, default: false },

    label: { type: String, required: true },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },

    text: { type: Boolean, default: true },
    filled: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
  },

  emits: ["click"],

  computed: {
    classes() {
      return {
        icon: !!this.$slots.default,
        loading: this.loading,
        disabled: this.disabled,

        text: this.isText,
        filled: this.isFilled,
        outlined: this.isOutlined,
      };
    },

    isText() {
      return !this.isFilled && !this.isOutlined && this.text;
    },

    isFilled() {
      return !this.isOutlined && this.filled;
    },

    isOutlined() {
      return this.outlined;
    },
  },

  methods: {
    onclick(e: Event) {
      if (this.disabled || this.loading) return;
      else if (this.to) {
        if (this.replace) this.$router.replace(this.to as any);
        else this.$router.push(this.to as any);
      } else this.$emit("click", e);
    },
  },
});
