import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onclick && _ctx.onclick(...args))),
    class: "top-app-bar-item"
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.iconClass),
      textContent: _toDisplayString(_ctx.icon)
    }, null, 10, _hoisted_1)
  ]))
}