
import { defineComponent } from "vue";

export default defineComponent({
  name: "Toast",

  data: () => ({
    timeout: 0 as unknown as NodeJS.Timeout,
  }),

  computed: {
    text() {
      return this.$device.toastText;
    },
    zIndex() {
      return this.$device.toastZIndex;
    },
    navigationIsHidden() {
      return this.$route.meta.blank;
    },
  },

  watch: {
    text(value) {
      clearTimeout(this.timeout);
      if (!value) return;

      this.timeout = setTimeout(this.$device.toast, 3500);
    },
  },
});
